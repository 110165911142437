import React, { useState } from "react"
import { Accordion } from "react-bootstrap"
import images from "../../assets/images"
import "./Footer.css"
import { Link } from "gatsby"
import Popup from "../Popup/Popup"
import ImageLazy from "../ImageLazy/ImageLazy"

function Footer(props) {
  const [isOpenPopUp, togglethePopUp] = useState(false)
  const [activeId, setActiveId] = useState("0")

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }

  return (
    <div className="Footer pt-4">
      <div className="container container-seformer">
        <div className="row hideFooter">
          <div className="col">
            <h3 className="titreFooter">Se Former</h3>
            <ul>
              {/* <li><Link to={'/qui-sommes-nous'}>Qui sommes-nous ?</Link></li> */}
              <li>
                <Link to={"/cgv"}>CGV</Link>
              </li>
              <li>
                <Link to={"/cgu"}>CGU</Link>
              </li>
              <li
                className="link-popup"
                onClick={() => {
                  togglethePopUp(!isOpenPopUp)
                }}
              >
                Contactez-nous
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="titreFooter">Zones de recherche</h3>
            <ul>
              <li>
                <Link to={"/annonces/la-reunion"}>La Réunion</Link>
              </li>
              <li>
                <Link to={"/annonces/mayotte"}>Mayotte</Link>
              </li>
              <li>
                <Link to={"/annonces/metropole"}>Métropole</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="titreFooter">Offre</h3>
            <ul>
              <li>
                <a
                  href={"https://www.domtomjob.com/"}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Offres d’emploi
                </a>
              </li>
              <li>
                <Link to={"/annonces"}>Formation</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="titreFooter">Formateurs</h3>
            <ul>
              <li
                className="link-popup"
                onClick={() => {
                  togglethePopUp(!isOpenPopUp)
                }}
              >
                Publier une offre
              </li>
            </ul>
          </div>
          <div className="col">
            <h3 className="titreFooter">Candidats</h3>
            <ul>
              <li>
                <a
                  target="_blank"
                  href={"https://www.domtomjob.com/emploi_cv_outre-mer.html"}
                  rel="noreferrer noopener"
                >
                  Déposer un CV
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="showMobile">
          <Accordion defaultActiveKey={activeId}>
            <div
              className={
                activeId === "0" ? "panel-wrap active-panel" : "panel-wrap"
              }
            >
              <div className="panel-header">
                <Accordion.Toggle
                  onClick={() => toggleActive("0")}
                  className="panel-toggle"
                  variant="link"
                  eventKey="0"
                >
                  Se Former
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body">
                  <ul>
                    {/* <li><Link to={'/qui-sommes-nous'}>Qui sommes-nous ?</Link></li> */}
                    <li>
                      <Link to={"/cgv"}>CGV</Link>
                    </li>
                    <li>
                      <Link to={"/cgu"}>CGU</Link>
                    </li>
                    <li
                      className="link-popup"
                      onClick={() => {
                        togglethePopUp(!isOpenPopUp)
                      }}
                    >
                      Contactez-nous
                    </li>
                  </ul>
                </div>
              </Accordion.Collapse>
            </div>

            <div
              className={
                activeId === "1" ? "panel-wrap active-panel" : "panel-wrap"
              }
            >
              <div className="panel-header">
                <Accordion.Toggle
                  onClick={() => toggleActive("1")}
                  className="panel-toggle"
                  variant="link"
                  eventKey="1"
                >
                  Formateurs
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body">
                  <ul>
                    <li
                      className="link-popup"
                      onClick={() => {
                        togglethePopUp(!isOpenPopUp)
                      }}
                    >
                      Publier une offre
                    </li>
                  </ul>
                </div>
              </Accordion.Collapse>
            </div>

            <div
              className={
                activeId === "2" ? "panel-wrap active-panel" : "panel-wrap"
              }
            >
              <div className="panel-header">
                <Accordion.Toggle
                  onClick={() => toggleActive("2")}
                  className="panel-toggle"
                  variant="link"
                  eventKey="2"
                >
                  Candidats
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href={
                          "https://www.domtomjob.com/emploi_cv_outre-mer.html"
                        }
                        title="Déposer un CV"
                        rel="noreferrer noopener"
                      >
                        Déposer un CV
                      </a>
                    </li>
                  </ul>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
        <div className="content-copyright">
          <p className="copyright">
            seformer.re
            <br />
            @2023
          </p>
        </div>
      </div>

      <ul className="sharing-flooting">
        <li>
          <a
            href="https://www.facebook.com/domtomjob/"
            target="_blank"
            title="Facebook"
            rel="noreferrer noopener"
          >
            <ImageLazy src={images.facebook} alt="icone facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://fr.linkedin.com/showcase/domtomjob.com"
            target="_blank"
            title="linkdin"
            rel="noreferrer noopener"
          >
            <ImageLazy src={images.linkdin} alt="icone linkdin" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/domtom_job?lang=fr"
            target="_blank"
            title="Twitter"
            rel="noreferrer noopener"
          >
            <ImageLazy src={images.twitter} alt="icone twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/domtomjob/?hl=fr"
            target="_blank"
            title="Instagram"
            rel="noreferrer noopener"
          >
            <ImageLazy src={images.instagram} alt="icone instagram" />
          </a>
        </li>
      </ul>
      {isOpenPopUp && <Popup togglethePopUp={() => togglethePopUp(false)} />}
    </div>
  )
}

export default Footer
