import React, { useState } from "react"
import images from "../../assets/images"
import "./Header.css"
import Wrapper from "../Wrapper/Wrapper"
import { Link } from "gatsby"
import ImageLazy from "../ImageLazy/ImageLazy"
import Img from "gatsby-image"

function Header(props) {
  const [isShowMenu, toggleMenu] = useState(false)
  const [isShowMenuAccount, toggleMenuAccount] = useState(false)

  const isPage = page => {
    if (page === "home") return props.location?.pathname === "/"
    if (page === "search")
      return (
        props.location?.pathname === "/annonces" ||
        props.location?.pathname === "/annonces/"
      )
    return false
  }

  return (
    <div className="header">
      <div className="container container-seformer">
        <header style={{ position: "relative" }}>
          <Wrapper className="header-area">
            <Wrapper className="main-header">
              <Wrapper className="header-bottom  header-sticky">
                <div className="d-flex align-items-center">
                  <div className="col-md-3 text-center d-none d-lg-block">
                    <div className="logo">
                      <Link
                        to={"/"}
                        title="offres de formation et de stage à La Réunion"
                      >
                        <Img
                          fluid={props.logo.childImageSharp.fluid}
                          alt="offres de formation et de stage à La Réunion"
                          className="img-fluid"
                          style={{ minWidth: "202px" }}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="col-md-9 col-lg-9 d-none d-lg-block">
                    <div className="main-menu ">
                      <nav>
                        <ul id="navigation">
                          <li>
                            {isPage("search") && (
                              <Link to={"/annonces"}>Formation</Link>
                            )}
                            {!isPage("search") && (
                              <Link to={"/annonces"}>Formation</Link>
                            )}
                          </li>

                          <li>
                            {isPage("search") && (
                              <Link to={"/annonces/?type=stage"}>Stage</Link>
                            )}
                            {!isPage("search") && (
                              <Link to={"/annonces/?type=stage"}>Stage</Link>
                            )}
                          </li>
                          <li>
                            <a
                              href={"https://www.domtomjob.com/"}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Emploi
                            </a>
                          </li>
                          <li className="publie_annonce">
                            <Link to={"/Abonnement"}>
                              Espace <br /> pro
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Creer-mon-compte"}>S’inscrire</Link>
                          </li>
                          <li>
                            <Link to={process.env.BO_URL}>Se connecter</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className="col-xl-12 col-md-12">
                    <div className="row align-items-center justify-content-around">
                      <div className="col-md-3">
                        <div
                          className="mobile_menu d-block d-lg-none"
                          onClick={() => {
                            toggleMenu(!isShowMenu)
                          }}
                        >
                          <ImageLazy
                            src={images.burgerMenu}
                            className="burgermenu"
                            alt="burger Menu"
                          />
                        </div>
                      </div>

                      {isShowMenu && (
                        <div className="main-menu menu-mobile d-lg-none">
                          <span
                            className="btnclose"
                            onClick={() => {
                              toggleMenu(!isShowMenu)
                            }}
                          >
                            <ImageLazy
                              src={images.iconeFermer}
                              className="imgclose"
                              alt="icone Fermer"
                            />
                          </span>
                          <nav>
                            <ul id="navigation">
                              <li>
                                <Link
                                  to={"https://www.domtomjob.com/"}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  <span className="iconeImgMenu">
                                    <ImageLazy
                                      alt="icone emploi mobile"
                                      src={images.iconeemploimobile}
                                    />{" "}
                                  </span>
                                  <span>Emploi</span>
                                </Link>
                              </li>

                              <li>
                                <Link to={"/annonces"}>
                                  <span className="iconeImgMenu">
                                    <ImageLazy
                                      alt="icone formation mobile"
                                      src={images.iconeformationmobile}
                                    />{" "}
                                  </span>
                                  <span>Formation</span>
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to={"/annonces/?items=10&page=1&type=stage"}
                                >
                                  <span className="iconeImgMenu">
                                    <ImageLazy
                                      alt="icone stage mobile"
                                      src={images.iconestagemobile}
                                    />{" "}
                                  </span>
                                  <span>Stage</span>
                                </Link>
                              </li>
                              <li className="publie_annonce">
                                <Link to={"/Abonnement"}>
                                  Espace <br /> pro
                                </Link>
                              </li>
                              <li className="publie_annonce">
                                <Link to={"/Creer-mon-compte"}>
                                  Créer <br /> mon compte
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      )}

                      <div className="col-md-3 d-block d-lg-none text-center">
                        <div className="logo">
                          <Link
                            to={"/"}
                            title="offres de formation et de stage à La Réunion"
                          >
                            <ImageLazy
                              src={images.logo}
                              alt="offres de formation et de stage à La Réunion"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div
                          className="mobile_menu d-block d-lg-none text-center user-mobilemenu"
                          onClick={() => {
                            toggleMenuAccount(!isShowMenuAccount)
                          }}
                        >
                          <ImageLazy
                            src={images.iconeusermobile}
                            alt="iconeusermobile"
                            className="useImg"
                          />
                        </div>
                      </div>

                      {isShowMenuAccount && (
                        <div className="main-menu menu-mobile menu-user d-lg-none">
                          <span
                            className="btnclose"
                            onClick={() => {
                              toggleMenuAccount(!isShowMenuAccount)
                            }}
                          >
                            <ImageLazy
                              src={images.iconeFermer}
                              className="imgclose"
                              alt="iconeFermer"
                            />
                          </span>
                          <nav>
                            <ul id="navigation">
                              <li>
                                <Link to={"/Creer-mon-compte"}>
                                  <ImageLazy
                                    src={images.iconeusermobile}
                                    alt="S inscrire"
                                  />{" "}
                                  <span>S'inscrire</span>
                                </Link>
                              </li>
                              <li>
                                <Link to={process.env.BO_URL}>
                                  <ImageLazy
                                    src={images.iconelogoutmobile}
                                    alt="Se connecter"
                                  />
                                  <span>Se connecter</span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </header>
      </div>
    </div>
  )
}

export default Header
