import React, { useEffect, useState } from "react"
import styled from "styled-components"
import GatsbyImg from "gatsby-image"

const BackgroundImage = styled.div`
  background-image: url(${props => (props.dataSrc ? props.dataSrc : "")});
`

export const BackgroundLazyImage = ({ className, src, children }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    // window.addEventListener("load", () => {
    setTimeout(() => {
      setLoaded(true)
    }, 4200)
    // })
  }, [])
  return (
    <BackgroundImage className={className} dataSrc={loaded ? src : ""}>
      {children ? children : ""}
    </BackgroundImage>
  )
}

export default props => {
  const attribute = { className: props.className, alt: props.alt }

  const Fluid = () => {
    return (
      <GatsbyImg fluid={props.fluid.childImageSharp.fluid} {...attribute} />
    )
  }

  const Img = () => {
    return <img {...attribute} src={props.src || ""} loading="lazy" />
  }

  return props.fluid ? <Fluid /> : <Img />
}
